import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [message, setMessage] = useState('Serverul nu rulează.');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.chitisimarius.com');
        setMessage(response.data.message);
      } catch (error) {
        console.error('Nu se poate conecta la server.', error);
      }
    };

    fetchData();
  }, []);

  const circleStyle = message === 'Serverul este activ!' ? "loadingCircle" : "errorCircle";

  return (
    <div className="messageContainer">
      {message}
      <div className={circleStyle}></div>
    </div>
  );
}

export default App;